/** @jsx jsx */
import { jsx } from '@emotion/core'

import {
  Content,
  Metric,
  Pill,
  VerticalLine
} from './styles'

const LinkableHeader = ({ text }) => {
  const id = (text || '').toLowerCase().replace(/[\s\W]+/g, '_')
  return (
    <h2 id={id}>
      {text}<a href={`#${id}`} style={{opacity: 0.3}}>#</a>
    </h2>
  )
}


function Hobby () {
  return (
    <Content>
      <h2>Fascinated Hobbyist</h2>
      <p>
        I once met someone who told me I was an "obbsessive maker". We were
        talking about all the different projects I had done over the years and I could see where he was coming from - I do obssessively make stuff and am fascinated by a lot of different stuff.
      </p>
      <p>
        Here's a short list of things that I've dabbled in over the years:
        playing guitar, writing and recording my own music, learning a little
        bit of other languages, making websites, making games, learning about
        web frameworks, making social networks, making decentralised social
        networks, creating a blog site, writing blogs, starting - not yet
        finishing - short novels, generating art, attempting to bake,
        travelling to other countries, walking in nature, cooking new recipes,
        reading fiction, non-fiction and honestly there're probably a few
        things I've missed.
      </p>
      <p>
        I've recently started blogging a lot more frequently to
        <a className='external' href="/me/blog/" target="_blank">my blog</a>, which now has a variety
        of posts and will hopefully have more in future.
      </p>
      <div css={{ marginLeft: '50px' }}>
        <div className='flex-cols flex-sb'>
          <Metric>
            <h1>99+</h1>
            <span>code projects</span>
          </Metric>
          <Metric>
            <h1>6</h1>
            <span>languages on Duolingo</span>
          </Metric>
          <Metric>
            <h1>1</h1>
            <span>acoustic guitar</span>
          </Metric>
        </div>
      </div>
      <VerticalLine half />
      <LinkableHeader text={'Music'} />
      <p>
        Every once in a while, I pick up my guitar and record songs on my
        phone. I've been playing guitar since I was a child and have continued
        to satisfy my cravings to make music through making electronic music
        and more recently using a
        <a className='external' href='https://teenage.engineering/products/op-z' target='_blank'>portable synthesizer</a>.
      </p>
      <p>
        One day I hope to put my music up on this site, but for now you can
        find a few songs on my
        <a className='external' href='https://soundcloud.com/vurve' target='_blank'>soundcloud</a>.
      </p>
      <iFrame width="100%" height="300" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/761821933&color=%2344ffbb&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true"></iFrame>
      <VerticalLine half />
      <LinkableHeader text={'Art'} />
      <p>
        I've always enjoyed making art and since 2019 I've been very interested
        in the field of generative artistry. My most recent works can be found
        <a className='external' href='https://lukebarnard.co.uk/blog/tags/generated' target='_blank'>on my blog</a>. I'm hoping to do a couple of these every week.
      </p>
      <p>
        Here are a couple of excerpts:
      </p>
      <img src='https://lukebarnard.co.uk/blog/img/generated-2020-jun-11/images/Screenshot%202020-06-11%20at%2018.28.02.png' />
      <VerticalLine half />
      <img src='https://lukebarnard.co.uk/blog/img/generated-2020-jun-15/images/Screenshot%202020-06-15%20at%2020.18.17.png' />
      <VerticalLine half />
      <LinkableHeader text={'Code'} />
      <p>
        One of my biggest passions is programming and I am constantly finding new projects
        to work on outside of my day-to-day work. I should note that as I write this, I am
        taking a holiday in the middle of the week after what has been a very
        busy few weeks at work. Most of my day will be spent coding :)
      </p>
      <p>
        Ever since I was a wee lad, I've obsessively built stuff for the web. But outside of my web-based antics, I can be found writing
        <a className='external' href='https://lukebarnard.co.uk/blog/tags/nomad' target='_blank'>a terminal multiplexer</a> that I use for software development at home and at
          work. This is one of few projects that I've spent time on in recent
          years that isn't web-based, and I keep coming back to it with renewed
          ambition. One day I hope to write a guide to developing a terminal
          with the intention of inspiring others to do the same.
      </p>
      <p>
        For a more detailed view of exactly what I've created and published, see
        <a className='external' href='https://github.com/lukebarnard1' target='_blank'>my GitHub profile</a>.
      </p>
      <p>
        For a fine-grained log of exactly what I'm working on, see
        <a className='external' href='https://lukebarnard.co.uk/blog/log' target='_blank'>my personal short-form blog.</a>
      </p>

    </Content>
  )
}

export default Hobby
