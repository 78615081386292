import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom'

import Dev from './Dev'
import Edu from './Edu'
import Hobby from './Hobby'

import styled from '@emotion/styled'

import {
  Content,
  Metric,
  VerticalLine
} from './styles'

const Container = styled.div`
  margin: 0 auto;
  padding: 0 10px;
  max-width: 600px;
`

const Section = styled(Link)`
  display: inline-block;

  color: initial;
  text-decoration: none;

  position: relative;
  left: 0px;
  transition: left 0.5s;

  :hover, :focus {
    left: 25px;
    opacity: 0.7;
  }

  h2 {
    :after {
      content: ' →';
    }
  }
`

function Main ({ match }) {
  return (
    <Content>
      <Section to='/me/dev' tabIndex='0'>
        <h2>
          Experienced Developer
        </h2>
        <p>
          I've been bringing my crazy ideas to industry
          since 2014 when I did a short internship in
          London. Writing software in industry brings
          the value of my code to people in the most
          useful ways.
        </p>
        <div className='flex-cols'>
          <Metric>
            <h1>8y</h1>
            <span>in industry</span>
          </Metric>
          <Metric>
            <h1>38+</h1>
            <span>major projects</span>
          </Metric>
          <Metric>
            <h1>2y</h1>
            <span>open source</span>
          </Metric>
        </div>
      </Section>
      <VerticalLine half />
      <Section to='/me/edu'>
        <h2>Intrigued CompSci</h2>
        <p>
          Computers have been an obsession of mine since
          I learned how to create webpages with HTML.
          Studying a degree in Computer Science was a
          no-brainer. I especially enjoyed learning about
          the intricacies of networking and the mathematics
          of computer graphics.
        </p>
        <div className='flex-cols'>
          <Metric>
            <h1>77%</h1>
            <span>grade awarded</span>
          </Metric>
          <Metric>
            <h1>1st</h1>
            <span>class BSc</span>
          </Metric>
          <Metric>
            <h1>1y</h1>
            <span>placement</span>
          </Metric>
        </div>
      </Section>
      <VerticalLine half />
      <Section to='/me/hob'>
        <h2>Fascinated Hobbyist</h2>
        <p>
          In my downtime, I can often be found on my sofa
          making changes to a project or live tweeting my
          latest deep dive into an obscure part of computing
          history. I might even be learning a language or
          recording an album.
        </p>
        <div className='flex-cols'>
          <Metric>
            <h1>99+</h1>
            <span>code projects</span>
          </Metric>
          <Metric>
            <h1>6</h1>
            <span>languages on Duolingo</span>
          </Metric>
          <Metric>
            <h1>1</h1>
            <span>acoustic guitar</span>
          </Metric>
        </div>
      </Section>
    </Content>
  )
}

function Error ({ code }) {
  return (
    <Content>
      <p>
        error code {code}. yikes.
      </p>
      <ul>
        <li>
          <Link to='/me'>
            go home
          </Link>
        </li>
        <li>
          <a href='#' onClick={() => window.history.back()}>
            go back
          </a>
        </li>
      </ul>
    </Content>
  )
}

const WorkInProgress = () => (
  <Content>
    <h2>WIP</h2>
    <p>
      This bit isn't ready yet - coming soon!
    </p>
    <ul>
      <li>
        <Link to='/me'>
          go home
        </Link>
      </li>
      <li>
        <a href='#' onClick={() => window.history.back()}>
          go back
        </a>
      </li>
    </ul>
  </Content>
)

function App () {
  return (
    <Router>
      <Content>
        <PageTransition />
      </Content>
    </Router>
  )
}

const IFrameFullScreen = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  border: 0;
`

const MainContainer = styled.div`
  position: relative;
  overflow: auto;
`

const PageFlexCol = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;

  > *:last-child {
    flex-grow: 1;
  }
  `

function Frame (props) {
  return (
    <IFrameFullScreen src={'https://lukebarnard.co.uk/' + props.match.url.split('/').slice(-2, -1).pop()} />
  )
}

const PageTransition = withRouter(({ location }) => {
  const { pathname, hash } = location

  useEffect(() => {
    document.title = 'luke.b/' + pathname/* {
      '/me': '',
      '/me/dev': ' - Experienced Developer',
      '/me/edu': ' - Intrigued CompSci',
      '/me/wip': ' - WIP'
    }[pathname]
    */
    const el = document.getElementById(hash ? hash.slice(1) : 'scroll-top')
    if (!el) return

    if (!hash) {
      el.scrollIntoView({ block: 'center' })
    } else {
      setTimeout(() => {
        el.scrollIntoView({ block: 'center' })
      }, 400)
    }
  }, [pathname, hash])

  const slogans = [
    'A crazy ideas thinker',
    'Coming up with new boxes to think outside of',
    'Obsessed with computers, constantly coding',
    'Open source enthusiast',
    'Passionate about empowering users',
    'Experienced developer'
  ]
  const slogan = slogans[Math.floor(Math.random() * slogans.length)]

  return (
    <PageFlexCol>
      <Container>
        <nav>
          <Link to='/me/'>me</Link>
          <Link to='/me/blog/'>blog</Link>
          <Link to='/me/insta-scrabble/'>instascrabble</Link>
          {/* external links */}
          <a href='https://github.com/lukebarnard1'>github</a>
          <a href='https://twitter.com/lukebarnard_'>twitter</a>
          <a href='https://www.linkedin.com/in/lukedevelops/'>linkedin</a>
          <a href='https://soundcloud.com/vurve'>soundcloud</a>
        </nav>
      </Container>
      <MainContainer>
        <Switch>
          <Route path='/me/insta-scrabble/' exact component={Frame} key='instascrabble' />
          <Route path='/me/blog/' exact component={Frame} key='blog' />
          <Route path='/me'>
            <Container>
              <div id='scroll-top' />
              <Section to='/me' key={pathname}>
                <h1>
                Luke Barnard
                </h1>
                <p className='mute'>
                  {slogan}
                </p>
              </Section>
              <VerticalLine />
              <Route path='/me' exact component={Main} key='main' />
              <Route path='/me/dev' exact component={Dev} key='dev' />
              <Route path='/me/edu' exact component={Edu} key='edu' />
              <Route path='/me/hob' exact component={Hobby} key='hob' />
              <Route path='/me/wip' exact component={WorkInProgress} key='wip' />
              <Route path='/me/minecraft' exact key='mc'>
                <h2>i'm running a minecraft</h2>
                <p>
                  point your minecraft client to this address to join in - lukebarnard.co.uk:41947
                </p>
              </Route>
              <VerticalLine />
              <p>
              designed + built by <a href='//lukebarnard.co.uk'>luke.b</a> &copy; {1900 + new Date().getYear()}
              </p>
            </Container>
          </Route>
          <Route path='*' render={() => <Error code={404} />} key='err404' />
        </Switch>
      </MainContainer>
    </PageFlexCol>
  )
})

export default App
