/** @jsx jsx */
import { jsx } from '@emotion/core'

import {
  Content,
  Metric,
  Pill,
  VerticalLine
} from './styles'

import loadedExperience from './experience.yaml'

const Paragraph = (text, ix) => {
  return (
    <p key={ix}>{text}</p>
  )
}

const Li = (text, ix) => {
  return (
    <li key={ix}>{text}</li>
  )
}

const LinkableHeader = ({ text }) => {
  const id = (text || '').toLowerCase().replace(/[\s\W]+/g, '_')
  return (
    <h2 id={id}>
      {text}
    </h2>
  )
}

const Experience = ({ isCurrent, text, isLast }) => {
  const {
    roleCompany,
    roleName,
    roleFlag = null,
    roleStart,
    roleEnd,
    roleDuration,
    roleTech = [],
    roleDesc = [],
    roleTask = []
  } = text
  if (roleFlag && new URLSearchParams(window.location.search).get('version') != roleFlag) {
    return null;
  }
  return (
    <div css={{ marginLeft: '55px' }}>
      <div className='pos-rel'>
        <LinkableHeader text={roleCompany} />
        <p className='mute'>
          <span className='label'>
            role:
          </span>
          {roleName}
        </p>
        { roleStart && (
          <p className='mute'>
            <span className='label'>
              started:
            </span>
            {roleStart}
          </p>
        )}
        { roleEnd && (
          <p className='mute'>
            <span className='label'>
              finished:
            </span>
            {roleEnd}
          </p>
        )}
        { roleDuration && (
          <p className='mute'>
            <span className='label'>
              duration:
            </span>
            {roleDuration}
          </p>
        )}
        <aside css={{ maxWidth: '30%', textAlign: 'right' }} className='pos-abs pos-tr'>
          {roleTech.map((t) => (<Pill name={t} key={t} />))}
        </aside>
      </div>
      <p>
        <span className='label'>
          {isCurrent ? 'so far' : 'the job' }:
        </span>
        {roleDesc[0]}
      </p>
      {roleDesc.slice(1).map(Paragraph)}
      <h3>tasks</h3>
      <ul>
        {roleTask.filter(t => t.type === 'task').map((t, ix) => Li(t.desc, ix))}
      </ul>
      <h3>achievements</h3>
      <ul>
        {roleTask.filter(t => t.type === 'goal').map((t, ix) => Li(t.desc, ix))}
      </ul>
      { !isLast && (<VerticalLine half />)}
    </div>
  )
}

function Dev () {
  return (
    <Content>
      <h2>Experienced Developer</h2>
      <p>
        My career as a developer started at a small internship where tasks included
        rewriting bits of code from one language to another and debugging wireless
        motion sensors. A few years later, degree in hand, I was ready to face tougher
        challenges, diving head-first into the world of open source. Eager to learn more
        I later joined ranks at a human layer security scale-up growing at unprecedented
        speeds.
      </p>
      <p>
        I always choose where to work carefully; I care a lot about the core mission
        and how the software affects those who use it. I always believe in the projects
        I am part of and will always bring my utmost enthusiasm to the challenges at hand.
      </p>
      <div css={{ marginLeft: '50px' }}>
        <div className='flex-cols flex-sb'>
          <Metric>
            <h1>8y</h1>
            <span>in industry</span>
            <VerticalLine half css={{ margin: '20px 0 0 35px' }} />
          </Metric>
          <Metric>
            <h1>38+</h1>
            <span>major projects</span>
          </Metric>
          <Metric>
            <h1>2y</h1>
            <span>open source</span>
          </Metric>
        </div>
      </div>
      {loadedExperience.map(
        (e, ix) => (
          <Experience
            key={ix}
            isCurrent={ix === 0}
            isLast={ix === loadedExperience.length - 1}
            text={e}
          />
        )
      )}
    </Content>
  )
}

export default Dev
