/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Link } from 'react-router-dom'

import {
  Content,
  Metric,
  Pill,
  VerticalLine
} from './styles'

import RAYTRACE_IMG from './raytrace.png'

function Edu () {
  return (
    <Content>
      <h2>Intrigued CompSci</h2>
      <p>
        Taking a bachelor's in computer science was a total no-brainer for me. I've
        always been completely fascinated by computers and how they work all the way
        up from CPU&nbsp;instructions to websites.
      </p><p>
        I was lucky enough to be offered a place at Bath University on a 4-year
        sandwich course that included a year in industry before my final year.
        During this time I studied a multitude of subjects and completed a number
        of individual and group projects.
      </p>
      <div css={{ marginLeft: '55px' }}>
        <div className='flex-cols flex-sb'>
          <Metric>
            <h1>77%</h1>
            <span>grade awarded</span>
          </Metric>
          <Metric>
            <h1>1st</h1>
            <span>class BSc (hons)</span>
          </Metric>
          <Metric>
            <h1>1y</h1>
            <span>placement</span>
          </Metric>
        </div>
        <VerticalLine half />
        <h2>Modules</h2>
        <p>
          Listed below are some personal highlights of my course.
        </p>
        <div className='pos-rel'>
          <aside css={{ maxWidth: '30%', textAlign: 'right' }} className='pos-abs pos-tr'>
            <Pill name='C' />
            <Pill name='C++' />
          </aside>
          <h3>Computer Graphics</h3>
          <p>
            I love to work on projects that give visual results. Part of what drew me
            to web development was the instant feedback of whether some component in the
            interface looks as intended. In the same way, developing a program with visual
            output requires attention to the visual aspects. As part of my computer graphics
            module, I quite enjoyed writing a ray tracer and exploring the many
            techniques in the field to recreate a visually accurate scene.
          </p>
          <p>
            <span className='label'>
              parts:
            </span>
            rasterised graphics, ray tracing, lighting methods, spline functions, shaders
          </p>
          <p>
            <span className='label'>
              score:
            </span>
            86%
          </p>
        </div>
        <VerticalLine half />
        <div>
          <h3>Networking</h3>
          <p>
            At uni, one of my friends called me a router for my extensive knowledge when
            it came to computer networking. The course was taught from the bottom up,
            exploring each layer of the TCP/IP stack one-by-one. I found it really
            fascinating and the knowledge I gained in this module is often crucially
            important in my day-to-day work.
          </p>
          <p>
            <span className='label'>
              parts:
            </span>
            Ethernet, IP, DNS, routing, UDP, TCP, TLS, HTTP, SMTP, firewalls, tunnelling
          </p>
          <p>
            <span className='label'>
              score:
            </span>
            77%
          </p>
        </div>
        <VerticalLine half />
        <div className='pos-rel'>
          <aside className='pos-abs pos-tr'>
            <Pill name='MatLab' />
          </aside>
          <h3>Computer Vision</h3>
          <p>
            Taught by the same professor teaching us computer graphics, I also had the
            opportunity to read the inverse pursuit of computer vision. The coursework
            was to generate image composites where two images are aligned by recognised
            "features" in the scene and then composited together; a process often known
            as "stitching".
          </p>
          <p>
            <span className='label'>
              parts:
            </span>
            image processing, feature normalisation and analysis, image compositing
          </p>
          <p>
            <span className='label'>
              score:
            </span>
            74%
          </p>
        </div>
        <VerticalLine half />
        <div className='pos-rel'>
          <aside className='pos-abs pos-tr'>
            <Pill name='C' />
          </aside>
          <h3>Parallel Computation</h3>
          <p>
            With a particular interest in the low-level details of how a computer
            can run multiple programs at once, I opted for this module in my final
            year. We learned a variety of techniques to achieve parallelism but also
            learned that more times than not, other optimisations should be tried
            first.
          </p>
          <p>
            <span className='label'>
              parts:
            </span>
            threading, synchronisation methods, distributed computing, parallel algorithms and data structures
          </p>
          <p>
            <span className='label'>
              score:
            </span>
            80%
          </p>
        </div>
        <VerticalLine half />
        <div>
          <h3>Programming</h3>
          <div css={{ marginTop: '10px', textAlign: 'right' }}>
            <Pill name='Java' />
            <Pill name='Lisp' />
            <Pill name='Prolog' />
            <Pill name='Python' />
          </div>
          <p>
            Throughout my bachelor's, I was taught a range of different
            programming paradigms and the basics of languages as examples of
            them. I was quite lucky to be taught Lisp and Prolog; languages not
            typically found in every software stack.
          </p><p>
            Typically we would be tasked with creating a simple program in the
            language we were studying and given a mark based on test coverage,
            performance or features implemented. In one module, we were asked
            to create a Java turn-based dungeon crawler. Another example was
            a polynomial arithmetic evaluator written in Lisp.
          </p>
          <p>
            I enjoyed learning new languages and the paradigms associated with
            them and would happily learn a language as part of my work.
          </p>
          <p>
            <span className='label'>
              parts:
            </span>
            language basics, language types, programming paradigms
          </p>
        </div>
      </div>
      <VerticalLine half />
      <h2>Projects</h2>
      <p>
        Below is a selection of projects that I'm particularly proud of from my
        uni days. I tended to enjoy assignments that involved a lot of
        programming and an emphasis on the visuals.
      </p>
      <div className='pos-rel'>
        <aside className='pos-abs pos-tr'>
          <Pill name='JavaScript' />
          <Pill name='PHP' />
        </aside>
        <h3>Unify</h3>
        <p>
          For my integrated project in my second year, I lead a small team of
          course mates and acted as head (and only) programmer. The idea
          (proposed by our professor) was to create a proximity-based social
          network. It came with Facebook-like features such as profile pictures,
          user and group feeds and a chat system. The caveat to adding someone
          to your list of friends was that your device needed to be co-located
          with the person you were adding.
        </p>
      </div>
      <div className='pos-rel'>
        <aside className='pos-abs pos-tr'>
          <Pill name='Django' />
          <Pill name='Python' />
        </aside>
        <h3>o2m</h3>
        <p className='mute'>
          One to Many - a distributed social network built in my spare time
          whilst at CERN
        </p>
        <p>
          Following my second year, I was still very much interested in
          creating social network sites with interesting properties. During my
          time developing Unify, I had been thinking a lot about a creating
          decentralised social network where individuals would run their own
          servers that would connect to their friends' servers. At this time I
          was starting to move away from the MAMP stack and I was working on a
          new project that was Django-based during my internship at CERN. So
          o2m was written in python on the Django framework.
        </p><p>
          Looking back at this project, I didn't get around to implementing a
          better solution to the inherent problem of security and trust within
          a decentralised network. This project still used the questionable
          method of authenticating with each friend's server using a password
          that might be changed after a request was sent. Still, this was
          mostly a pastime POC to understand the constraints of a decentralised
          architecture.
        </p>
      </div>
      <div className='pos-rel'>
        <aside className='pos-abs pos-tr'>
          <Pill name='Bootstrap' />
          <Pill name='React' />
        </aside>
        <h3>NDSN</h3>
        <p className='mute'>
          New Distributed Social Network - created for my Bachelor's dissertation
        </p>
        <p>
          Done with my internship and ready to start my final project, I
          decided that I wanted to work on a project of my own design -
          creating a secure distributed social network. I was incredibly lucky
          to be able to be able to work on a project outside of the list of
          approved projects. This was a challenge of re-engineering and gaining
          more understanding of the limitations of distributed systems, but
          this time in an academical context.
        </p>
        <p>
          Wanting to learn more modern web technologies, I embraced React as
          the framework to drive the client-side implementation (using Bootstrap
          to simplify and speed up UI development). Web sockets were used for
          all client-server communication. This was used in the implementation
          of the chat system and collaborative file-editing feature. Taking
          what I had learned with o2m, I decided to use&nbsp;
          <i>certificate pinning</i> to secure server-server communication.
          End-to-end encryption was a feature that was de-scoped in preference
          of actually writing the dissertation itself, and I have since learned
          that per-device end-to-end encryption is incredibly difficult.
        </p>
      </div>
      <div className='pos-rel'>
        <aside className='pos-abs pos-tr'>
          <Pill name='C' />
        </aside>
        <h3 id='ray_tracer'>Ray tracer</h3>
        <p className='mute'>coursework for a graphics module</p>
        <p>
          As I mentioned, I much prefer working on projects that produce a
          visual result. In my final year I took a graphics module where we
          were taught the fundamentals of computing images of objects in a
          virtual scene. I thoroughly enjoyed applying simple models of how
          light moves through physical mediums to achieve a realistic
          image of objects. The mathematics involved are fairly simple; mostly
          linear algebra. Everything else involves simple methods of modeling
          surfaces and mediums effectively and understanding how using a GPU
          can drastically improve performance.
        </p>
        <img src={RAYTRACE_IMG} />
      </div>
      <VerticalLine half />
      <h2>Sandwich Course Placement</h2>
      <p>
        At the University of Bath, most students go on a placement year after
        two years of study to work in industry. One of the reasons I chose to
        study at Bath was this opportunity, and it certainly didn't disappoint.
      </p><p>
        I applied to CERN twice and got offers both times, but I declined the
        first offer as it didn't interest me. The second time around I got an
        offer to work on a data visualisation project in one of the engineering
        teams responsible for testing the electrical quality of each
        of the 100s of the LHC's superconducting magnets.
      </p>
      <VerticalLine half />
    </Content>
  )
}

export default Edu
