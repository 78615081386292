import React from 'react'
import styled from '@emotion/styled'
export const Content = styled.div`

  h1 {
    font-size: 48px;
    margin-bottom: 0px;
  }

  h2 {
    margin-bottom: 0px;
    margin-top: 10px;
  }

  h3 {
    margin-bottom: 0px;
    font-weight: 500;
  }

  .pos-rel { position: relative }
  .pos-abs { position: absolute }

  .pos-tr { top: 0; right: 0; }

  .flex-cols {
    display: flex;
    flex-wrap: wrap-reverse;
  }

  .flex-sb {
    justify-content: space-between;
  }

  .mute {
    color: #555;
  }

  // For subtitles
  h1 + p {
    margin-top: 5px;
  }

  p, li {
    line-height: 1.5em;
  }

  p {
    text-align: justify;
  }

  ul {
    padding-inline-start: 0px;
  }

  li {
    list-style-type: none;
    text-indent: -13px;
    margin-bottom: 5px;

    * {
      text-indent: initial;
    }

    :before {
      content: '//';
      display: inline-block;
      right: 100%;
      height: 0px;
      margin: 0px;

      text-align: right;
      padding-right: 13px;
      color: #aaa;
    }
  }

  nav {
    background: #fff;
    display: flex;
    flex-wrap: wrap;

    a {
      border: 1px solid #000;
      margin: 2px;
      transition: 0.2s all;
      :hover,
      :focus {
        margin-top: 1px;
      }
    }
  }

  a {
    padding-inline-start: 0.2em;
    padding-inline-end: 0.2em;
      padding-block-end: 0.1em;
    color: inherit;
    text-decoration: none;
    border: 1px solid transparent;
    :hover,
    :focus {
      border: 1px solid black;
      outline: none;
      background: #5fc;
    }
  }

  a.external {
    color: #000;
    text-decoration: underline;
  }

  .label {
    display: block;

    position: relative;
    right: 100%;
    height: 0px;
    margin: 0px;

    text-align: right;
    padding-right: 13px;
    color: #aaa;
  }

  .pill {
    background: black;
    color: white;

    font-size: 0.9em;
    font-weight: initial;

    display: inline-block;
    border-radius: 2px;
    padding: 0px 3px;
    margin-inline-end: 5px;
    margin-block-end: 5px;

    line-height: 1.5em;

    user-select: none;
  }

  .javascript {  background: #f1e05a; color: #000 }
  .typescript {  background: #3178c6; color: #fff }
  .java {        background: #b07219; color: #fff }
  .react {       background: #67dbf9; color: #000 }
  .aws { background: #ff9b00; color: #202c3c }
  .concourse { background: #4a90e2; color: #fff }
  .storybook { background: #ff4785; color: #fff }
  .chromatic { background: #fd521f; color: #fff }
  .cypress { background: #69d3a7; color: #1b1e2f }
  .python {      background: #4483b5; color: #fff }
  .prolog {      background: #74283c; color: #fff }
  .django {      background: #2B8C67; color: #fff }
  .c {           background: #555555; color: #fff }
  .c\\+\\+ {     background: #f34b7d; color: #fff }
  .lisp {        background: #3fb68b; color: #fff }
  .matlab {      background: #e16737; color: #fff }
  .php {         background: #4f5d95; color: #fff }
  .bootstrap {   background: #5c4283; color: #fff }

  img {
    width: 90%;
    padding: 5%;
    box-shadow: 0px 10px 20px #ddd;
  }
`

export const Metric = styled.div`
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;

  padding: 6px 0px;
  min-width: 100px;
  margin-right: 50px;

  h1 {
    margin: 0px
  }

  span {
    font-size: 15px;
    max-width: 120px;
    color: #555;
  }
`
export const VerticalLine = styled.div`
  width: 0px;
  height: ${props => props.half ? 80 : 160}px;

  border-style: solid;
  border-color: #ccc;
  border-width: 0px 0px 0px 1px;

  margin-left: 50px;
`

export const Pill = ({ name }) => {
  return (
    <span key={name} className={'pill ' + name.toLowerCase()}>{name}</span>
  )
}
